module.exports = {
  customer: 'peterborough',
  environment: 'production',
  isBetterCare: true,
  yourMoney: true,
  jobsVolunteering: false,
  googleAnalyticsId: 'G-YBM9WF496D',
  robots: false,
  chatWidget: false,
  requirePostcode: false,
  entitledToBaseUrl: 'https://peterboroughbettercare.entitledto.co.uk',
  entitledToReassessUrl: 'https://peterboroughbettercare.entitledto.co.uk/reassessbetteroff',

  app: {
    home: 'https://peterborough.bettercare.org.uk'
  },
  serverBaseUrl: 'https://api-peterborough.bettercare.org.uk',
  api: {
    baseUrl: 'https://api-peterborough.bettercare.org.uk/internal/v1'
  },
  authGateway: 'https://account.peterborough.bettercare.org.uk',
  loginServices: [
    {name: 'kerp'},
    {name: 'phone'},
    {name: 'google', postLogoutUrl: 'https://accounts.google.com/Logout'},
    {name: 'facebook'},
    {name: 'agent'},
    {name: 'referenceNumber'}
  ],
  ui: {
    showJobSearchJournal: false,
    showOfflineForms: true,
    showAssessmentMutators: false,
    restrictions: {
      applications: false
    }
  },
  airbrake: {
    enabled: true,
    projectId: 131082,
    projectKey: 'facc9a060872b357453b677f15d319e3'
  }
};
